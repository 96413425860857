'use client'

import {
  ColumnDef,
  ColumnDefTemplate,
  flexRender,
  getCoreRowModel,
  HeaderContext,
  Row,
  useReactTable,
  VisibilityState as VisibilityStateProps
} from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import Empty from '~/core/ui/Empty'
import { IconButton } from '~/core/ui/IconButton'
import { cn } from '~/core/ui/utils'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const getStyleRow = ({
  thead,
  index,
  checked = false,
  config = { sticky: [], scrollXLeft: false, scrollXRight: false }
}: {
  thead?: boolean
  index: number
  checked: boolean
  config: {
    sticky?: Array<{
      index?: number
      position?: string
      value?: number
      useShadow?: boolean
    }>
    scrollXLeft: boolean
    scrollXRight: boolean
  }
}) => {
  const filter = (config.sticky || []).filter(
    (session) => session.index == index
  )
  const getFilter = filter && filter[0]
  const getClassShadow = `${
    getFilter?.useShadow
      ? getFilter.position === 'left'
        ? config.scrollXLeft
          ? `before:content-[''] before:absolute before:top-0 before:right-0 before:-bottom-[1px] before:pointer-events-none before:translate-x-full before:w-[30px] before:shadow-[10px_0_8px_-6px_inset_rgba(5,5,5,0.07)]`
          : ''
        : config.scrollXRight
        ? `before:content-[''] before:absolute before:top-0 before:left-0 before:-bottom-[1px] before:pointer-events-none before:-translate-x-full before:w-[30px] before:shadow-[-10px_0_8px_-6px_inset_rgba(5,5,5,0.07)]`
        : ''
      : ''
  }`

  const getRowWithShadow = `min-h-[40px] first:pl-0 px-3 ${
    thead ? 'py-[9px]' : 'py-2'
  } ${getFilter && checked === false ? 'bg-white' : ''} ${
    getFilter && checked === true ? 'bg-gray-50' : ''
  } ${!getFilter ? 'bg-transparent' : ''} ${getClassShadow}`

  const customStyle = {
    position: getFilter ? 'sticky' : 'inherit',
    left:
      getFilter && getFilter.position === 'left'
        ? index === 0
          ? 0
          : getFilter.value
        : 'inherit',
    right:
      getFilter && getFilter.position === 'right'
        ? index === 0
          ? 0
          : getFilter.value
        : 'inherit'
  }

  return {
    getRowWithShadow,
    customStyle
  }
}

const getTheadClass = (isHeaderSticky: boolean) => {
  return `bg-white dark:bg-gray-900 ${
    isHeaderSticky ? 'sticky top-0 left-0 right-0 z-10' : ''
  }`
}

const getWrapperClass = (classNameTable: string) => {
  return `overflow-x-auto overflow-y-auto ${classNameTable}`
}

interface dataQueryTablePaginationProps {
  data?: {
    data: Array<object>
    meta?: {
      totalRowCount?: number
      currentPage?: number
    }
  }
  isFetching?: boolean
  fetcher?: {
    fetchNextPage?: () => void
    fetchPreviousPage?: () => void
  }
}

interface TablePaginationProps {
  textOverride?: {
    [key: string]: string
  }
  search?: {
    globalFilter?: string | number
    setGlobalFilter?: (value: string) => void
    filter?: Object
  }
  emptyConfig?: {
    classNameEmpty?: string
    title?: string
    description?: string
    buttonTitle?: string
    buttonTitleOnClick?: () => void
    titleSearch?: string
    descriptionSearch?: string
    buttonTitleSearch?: string
    buttonTitleSearchOnClick?: () => void
  }
  tableConfig: {
    defaultPageSize: number
  }
  dataQuery?: dataQueryTablePaginationProps
  columns?: ColumnDef<any, undefined>[]
  stickyConfig?: Array<{
    index?: number
    position?: string
    value?: number
    useShadow?: boolean
  }>
  classNameTable?: string
  classNamePaginationWrapper?: string
  isHeaderSticky?: boolean
  classNameSearchInput?: string
  placeholderSearchInput?: string
  columnVisibility?: VisibilityStateProps | undefined
}

const TbodyTable: FCC<{
  rows?: Array<Row<object>>
  columns: ColumnDef<any, undefined>[]
  dataQuery?: dataQueryTablePaginationProps
  configMode: {
    paddingTop: number
    paddingBottom: number
    sticky?: Array<{
      index?: number
      position?: string
      value?: number
      useShadow?: boolean
    }>
    scrollXLeft: boolean
    scrollXRight: boolean
    defaultPageSize?: number
  }
}> = ({ rows = [], dataQuery = {}, configMode, columns }) => {
  const rendered = (row: Row<object>) => {
    return (
      <tr
        key={row.id}
        className={cn(
          'border-b-solid table-tbody-hover border-b-[1px] border-b-gray-100',
          !row.getIsSelected()
            ? 'bg-white dark:bg-gray-900'
            : 'table-tbody-selected'
        )}>
        {row.getVisibleCells().map((cell, index) => {
          const { customStyle, getRowWithShadow } = getStyleRow({
            thead: false,
            index,
            checked: row.getIsSelected(),
            config: {
              sticky: configMode.sticky,
              scrollXLeft: configMode.scrollXLeft,
              scrollXRight: configMode.scrollXRight
            }
          })

          return (
            <td
              key={cell.id}
              className={getRowWithShadow}
              style={{
                width: cell.column.getSize(),
                ...JSON.parse(JSON.stringify(customStyle))
              }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <tbody>
      {!dataQuery.isFetching ? (
        <>{rows.map((row: Row<object>) => rendered(row))}</>
      ) : (
        <>
          {[...Array(configMode.defaultPageSize || 25).keys()].map((index) => {
            return (
              <tr key={index}>
                {columns.map((item, i) => {
                  const { customStyle, getRowWithShadow } = getStyleRow({
                    thead: false,
                    index: i,
                    checked: false,
                    config: {
                      sticky: configMode.sticky,
                      scrollXLeft: configMode.scrollXLeft,
                      scrollXRight: configMode.scrollXRight
                    }
                  })
                  return (
                    <td
                      key={i}
                      className={getRowWithShadow}
                      style={JSON.parse(JSON.stringify(customStyle))}>
                      <div className="h-4 w-full rounded-[2px] bg-gray-100" />
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </>
      )}
    </tbody>
  )
}

const TheadTable: FCC<{
  table: {
    getHeaderGroups: () => Array<{
      id: string
      headers: Array<{
        id: string
        colSpan: number
        getSize: Function
        getContext: Function
        isPlaceholder?: boolean
        column: {
          columnDef: {
            header?:
              | ColumnDefTemplate<HeaderContext<object, unknown>>
              | undefined
          }
        }
      }>
    }>
  }
  isHeaderSticky: boolean
  sticky?: Array<{
    index?: number
    position?: string
    value?: number
    useShadow?: boolean
  }>
  scrollXLeft: boolean
  scrollXRight: boolean
}> = ({ table, isHeaderSticky, sticky, scrollXLeft, scrollXRight }) => {
  return (
    <thead className={getTheadClass(isHeaderSticky)}>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const { customStyle, getRowWithShadow } = getStyleRow({
              thead: true,
              index,
              checked: false,
              config: {
                sticky,
                scrollXLeft,
                scrollXRight
              }
            })

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: header.getSize(),
                  ...JSON.parse(JSON.stringify(customStyle))
                }}
                className={`border-b-solid border-b-[1px] border-b-gray-100 ${getRowWithShadow}`}>
                {header.isPlaceholder ? null : (
                  <div className="text-left text-xs font-normal text-gray-600 dark:text-gray-300">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

const TablePagination = ({
  textOverride,
  search = {},
  emptyConfig,
  tableConfig = {
    defaultPageSize: 25
  },
  dataQuery = {},
  columns = [],
  stickyConfig = [],
  classNameTable = '',
  classNamePaginationWrapper = '',
  isHeaderSticky = false,
  columnVisibility
}: TablePaginationProps) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const defaultData = React.useMemo(() => [], [])
  const sticky = React.useMemo(() => stickyConfig, [stickyConfig])
  const [columnVisibilityState, setColumnVisibility] = useState(
    columnVisibility || {}
  )
  const positionRef = React.useRef(0)
  const [scrollXLeft, setScrollXLeft] = useState(false)
  const [scrollXRight, setScrollXRight] = useState(false)
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const x = e.currentTarget.scrollLeft
    if (x !== positionRef.current) {
      positionRef.current = x
      setScrollXLeft(x !== 0)
      setScrollXRight(x !== 0)
    }
  }

  useEffect(() => {
    if (columnVisibility) {
      setColumnVisibility(columnVisibility)
    }
  }, [columnVisibility])

  const table = useReactTable({
    data: dataQuery.data?.data || defaultData,
    columns,
    state: {
      columnVisibility: columnVisibilityState
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel()
  })

  const { rows } = table.getRowModel()

  const mergedEmptyConfig = {
    ...(emptyConfig || {}),
    show: dataQuery.isFetching === false && rows.length === 0
  }
  const isSearchEmpty = mergedEmptyConfig.show && !!search.globalFilter
  const currentPage = dataQuery.data?.meta?.currentPage || 1
  const defaultPageSize = tableConfig.defaultPageSize
  const totalRowCount = dataQuery.data?.meta?.totalRowCount || 1

  return (
    <>
      {mergedEmptyConfig.show === false ? (
        <>
          <div
            ref={tableContainerRef}
            className={getWrapperClass(classNameTable)}
            onScroll={handleScroll}>
            <table className="w-full table-fixed border-collapse border-spacing-0">
              <TheadTable
                table={table}
                isHeaderSticky={isHeaderSticky}
                sticky={sticky}
                scrollXLeft={scrollXLeft}
                scrollXRight={scrollXRight}
              />

              <TbodyTable
                rows={rows}
                columns={columns}
                dataQuery={dataQuery}
                configMode={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  sticky,
                  scrollXLeft,
                  scrollXRight,
                  defaultPageSize
                }}
              />
            </table>
          </div>

          {isSearchEmpty === false ? (
            <div
              className={`flex items-center justify-end space-x-2 py-3 ${classNamePaginationWrapper}`}>
              <p className="m-0 text-xs text-gray-600">
                {((currentPage - 1) * defaultPageSize || 0) + 1}-
                {totalRowCount > defaultPageSize &&
                totalRowCount > defaultPageSize * currentPage
                  ? defaultPageSize * currentPage
                  : totalRowCount}{' '}
                {textOverride?.of || 'of'} {totalRowCount}
              </p>
              <IconButton
                size="xs"
                type="secondary"
                isDisabled={dataQuery.isFetching || currentPage === 1}
                onClick={() => {
                  if (dataQuery.fetcher?.fetchPreviousPage) {
                    dataQuery.fetcher.fetchPreviousPage()
                  }
                }}
                iconMenus="ChevronLeft"
              />

              <IconButton
                size="xs"
                type="secondary"
                isDisabled={
                  dataQuery.isFetching ||
                  currentPage * defaultPageSize >= Number(totalRowCount)
                }
                onClick={() => {
                  if (dataQuery.fetcher?.fetchNextPage) {
                    dataQuery.fetcher.fetchNextPage()
                  }
                }}
                iconMenus="ChevronRight"
              />
            </div>
          ) : null}
        </>
      ) : (
        <div className={mergedEmptyConfig.classNameEmpty}>
          <div className="text-center">
            <Empty
              onClick={
                isSearchEmpty
                  ? mergedEmptyConfig.buttonTitleSearchOnClick
                  : mergedEmptyConfig.buttonTitleOnClick
              }
              type={isSearchEmpty ? 'empty-search' : 'empty-data'}
              title={
                isSearchEmpty
                  ? mergedEmptyConfig.titleSearch
                  : mergedEmptyConfig.title
              }
              description={
                isSearchEmpty
                  ? mergedEmptyConfig.descriptionSearch
                  : mergedEmptyConfig.description
              }
              buttonTitle={
                isSearchEmpty
                  ? mergedEmptyConfig.buttonTitleSearch
                  : mergedEmptyConfig.buttonTitle
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export { TablePagination }
export type { dataQueryTablePaginationProps, TablePaginationProps }
