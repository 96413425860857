import { gql } from 'urql'

const QueryCompanyJobList = gql`
  query (
    $page: Int
    $limit: Int
    $search: String
    $companyId: Int
    $country: String
    $state: String
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $countryStateId: Int
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsList(
      page: $page
      limit: $limit
      search: $search
      companyId: $companyId
      country: $country
      state: $state
      status: $status
      hiringMemberIds: $hiringMemberIds
      countryStateId: $countryStateId
      tagIds: $tagIds
      operator: $operator
    ) {
      collection {
        id
        title
        status
        statusDescription
        statistic {
          newApplicantCount
          applicantsCount
          inprocessGroupCount
          hiredGroupCount
        }
        jobLocations {
          name
          state
          country
        }
        department {
          name
        }
        jobRecruiters {
          id
          user {
            email
            fullName
            defaultColour
            avatarVariants
          }
        }
        clientMembers {
          id
          email
          fullName
          defaultColour
          avatarVariants
        }
        company {
          id
          name
        }
        applicants {
          id
        }
      }
      metadata {
        currentPage
        totalCount
      }
    }
  }
`

export default QueryCompanyJobList
