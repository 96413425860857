import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QueryOpenJobs from '../../referrals/graphql/query-open-jobs'

import {
  IPagePaginationResult,
  usePaginationQuerySearch
} from '~/lib/hooks/use-pagination-query-search'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import configuration from '~/configuration'
import useBoundStore from '~/lib/store'

export const PAGE_SIZE_OPEN_JOBS_MANAGEMENT = 8

const useOpenJobsDashboard = () => {
  const { t } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const setToast = useBoundStore((state) => state.setToast)
  const [page, setPage] = useState<number>(1)

  const fetchData = useCallback(
    (
      pageParam = {} as { page?: number; limit?: number }
    ): Promise<IPagePaginationResult<any>> => {
      return clientGraphQL
        .query(QueryOpenJobs, {
          limit: PAGE_SIZE_OPEN_JOBS_MANAGEMENT,
          page: pageParam.page || page
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.reports.overview,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                currentPage: 1
              }
            }
          }

          const collection = Object.values(result.data)?.[0]?.collection || []
          const metadata = Object.values(result.data)?.[0]?.metadata || {}

          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              currentPage: page
            }
          }
        })
    },
    [clientGraphQL, page, setToast]
  )

  const {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    refetch
  } = usePaginationQuerySearch<any>({
    fetchData,
    queryKey: {
      key: 'open-job-dashboard',
      page,
      limit: PAGE_SIZE_OPEN_JOBS_MANAGEMENT
    }
  })

  const fetchNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const fetchPreviousPage = useCallback(() => {
    setPage(page - 1)
  }, [page])

  const refetchFirstPage = () => {
    refetch()
  }

  const onRefetch = () => {
    refetch()
  }

  return {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    fetchNextPage,
    fetchPreviousPage,
    refetchFirstPage,
    onRefetch
  }
}

export default useOpenJobsDashboard
