import { gql } from 'urql'
import { IJobManagementItemQuery } from '../../jobs/types'

const QueryJobList = gql<
  {
    jobsList: {
      collection: IJobManagementItemQuery[]
      metadata: { totalCount: number; currentPage: number }
    }
  },
  {
    search?: string
    departmentId?: number | string
    locationIds?: number[]
    status?: string[]
  }
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $departmentIds: [Int!]
    $locationIds: [Int!]
    $countryStateIds: [Int!]
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsList(
      limit: $limit
      page: $page
      search: $search
      departmentIds: $departmentIds
      countryStateIds: $countryStateIds
      locationIds: $locationIds
      status: $status
      hiringMemberIds: $hiringMemberIds
      tagIds: $tagIds
      operator: $operator
    ) {
      collection {
        id
        title
        status
        statusDescription
        statistic {
          newApplicantCount
          applicantsCount
          inprocessGroupCount
          hiredGroupCount
        }
        jobLocations {
          name
          state
          country
        }
        department {
          name
        }
        jobRecruiters {
          id
          user {
            email
            fullName
            defaultColour
            avatarVariants
          }
        }
        applicants {
          id
        }
      }
      metadata {
        currentPage
        totalCount
      }
    }
  }
`

export default QueryJobList
