import { gql } from 'urql'
import { JobDetailType, OpenJobsParams } from '../types'

const QueryOpenJobs = gql<
  {
    jobsReferableList: {
      collection: Array<JobDetailType>
      metadata: {
        totalCount: number
      }
    }
  },
  OpenJobsParams
>`
  query (
    $limit: Int
    $page: Int
    $search: String
    $locationIds: [Int!]
    $countryStateIds: [Int!]
    $departmentIds: [Int!]
    $locationId: Int
    $talentPoolIds: [Int!]
    $jobsKey: String
    $jobLevel: JobJobLevel
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsReferableList(
      limit: $limit
      page: $page
      search: $search
      locationIds: $locationIds
      countryStateIds: $countryStateIds
      departmentIds: $departmentIds
      locationId: $locationId
      talentPoolIds: $talentPoolIds
      jobsKey: $jobsKey
      jobLevel: $jobLevel
      tagIds: $tagIds
      operator: $operator
    ) {
      collection {
        id
        title
        slug
        createdAt
        referralsCount
        jobReferable
        jobLocations {
          state
          country
        }
        department {
          name
        }
        tenant {
          slug
        }
        status
        enablingReward
        rewardAmount
        rewardCurrency
        rewardGift
        referralRewardType
        publicReferralUri
        recommendationMatchedFields
        permittedFields
        currentUserAppliedAt
        savedReferralJobMemberIds
        tags {
          id
          name
        }
        skills
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryOpenJobs
