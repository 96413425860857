import { gql } from 'urql'
import { IUserSettingDashboardResponse } from '../types'

const QueryUserSettingDashboard = gql<
  {
    userSettingsDashboard: IUserSettingDashboardResponse
  },
  {}
>`
  query {
    userSettingsDashboard {
      id
      userId
      tenantId
      group
      groupDescription
      values
    }
  }
`

export default QueryUserSettingDashboard
