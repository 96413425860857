import { AnyVariables, TypedDocumentNode } from 'urql'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryUserSettingDashboard from '../graphql/query-user-setting-dashboard'

export function useQueryUserSettingDashboard() {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query: QueryUserSettingDashboard,
    variables: {}
  })

  const data = response?.userSettingsDashboard

  return {
    trigger,
    isLoading,
    data,
    error
  }
}
