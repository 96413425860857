import { useCallback, useEffect, useState } from 'react'
import { useInfinityGraphPage } from '../../jobs/hooks/use-infinity-graph-page'
import { trimObjectProps } from '../../tasks/utilities/common'
import QueryMyReferrals from '../../referrals/graphql/query-my-referrals'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import {
  IPagePaginationResult,
  usePaginationQuerySearch
} from '~/lib/hooks/use-pagination-query-search'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import configuration from '~/configuration'
import useBoundStore from '~/lib/store'

export const PAGE_SIZE_MY_REFERRALS_MANAGEMENT = 8

const useMyReferralsDashboard = () => {
  const [page, setPage] = useState<number>(1)
  const setToast = useBoundStore((state) => state.setToast)
  const { clientGraphQL } = useContextGraphQL()

  const fetchData = useCallback(
    (
      pageParam = {} as { page?: number; limit?: number }
    ): Promise<IPagePaginationResult<any>> => {
      return clientGraphQL
        .query(QueryMyReferrals, {
          limit: PAGE_SIZE_MY_REFERRALS_MANAGEMENT,
          page: pageParam.page || page
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.reports.overview,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                currentPage: 1
              }
            }
          }

          const collection = Object.values(result.data)?.[0]?.collection || []
          const metadata = Object.values(result.data)?.[0]?.metadata || {}
          const extras = Object.values(result.data)?.[0]?.metadata?.extras || {}
          // extras: {
          //   not_hired_count: number
          //   in_process_count: number
          //   hired_count: number
          //   my_referral_link: string
          // }
          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              currentPage: page,
              extras: {
                ...extras
              }
            }
          }
        })
    },
    [clientGraphQL, page, setToast]
  )

  const {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    refetch
  } = usePaginationQuerySearch<any>({
    fetchData,
    queryKey: {
      key: 'my-referral-dashboard',
      page,
      limit: PAGE_SIZE_MY_REFERRALS_MANAGEMENT
    }
  })

  const fetchNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const fetchPreviousPage = useCallback(() => {
    setPage(page - 1)
  }, [page])

  return {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    fetchNextPage,
    fetchPreviousPage
  }
}

export default useMyReferralsDashboard
