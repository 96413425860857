const QueryUpdateUserSettingDashboard = `
  mutation (
    $group: USettingGroup!,
    $values: [JSON!]!
  ) {
    userSettingsDashboardUpdate(
      input: {
        group: $group,
        values: $values,
      }
    ) {
      userSetting {
        group
        values
      }
    }
  }
`

export default QueryUpdateUserSettingDashboard
