import { formatISO, lastDayOfMonth, startOfMonth } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IUserInformation } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import {
  IInterviewsManagementFilter,
  InterviewParamsType
} from '../../calendar/types'
import { addTzToDate } from '../../calendar/utilities/helper-schedule-interview'
import QueryUpdateInterviewMutation from '../../calendar/graphql/mutation-update-interview'
import QueryInterviewsCandidateList from '../graphql/query-interviews-candidate-list'
import {
  IPagePaginationResult,
  usePaginationQuerySearch
} from '~/lib/hooks/use-pagination-query-search'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import configuration from '~/configuration'
import useBoundStore from '~/lib/store'

const limit = 4

export const Key_Tabs = {
  feedback_due: 'feedback_due',
  interview_upcoming: 'interview_upcoming'
}

const useMyInterviewsManagement = ({ user }: { user: IUserInformation }) => {
  const { clientGraphQL } = useContextGraphQL()
  const [filterValue, onChangeFilter] = useState<IInterviewsManagementFilter>({
    filterBy: Key_Tabs.interview_upcoming
  })
  const [page, setPage] = useState<number>(1)
  const setToast = useBoundStore((state) => state.setToast)
  const fetchData = useCallback(
    (
      pageParam = {} as { page?: number; limit?: number }
    ): Promise<IPagePaginationResult<any>> => {
      return clientGraphQL
        .query(QueryInterviewsCandidateList, {
          page: pageParam.page || page,
          limit,
          filterBy: filterValue?.filterBy || Key_Tabs.interview_upcoming
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.reports.overview,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                currentPage: 1
              }
            }
          }
          const collection = result.data.interviewsList?.collection || []
          const metadata = result.data.interviewsList?.metadata || {}

          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              currentPage: metadata.currentPage
            }
          }
        })
    },
    [clientGraphQL, page, setToast, filterValue]
  )

  const {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    refetch
  } = usePaginationQuerySearch<any>({
    fetchData,
    queryKey: {
      key: filterValue?.filterBy
        ? filterValue?.filterBy
        : Key_Tabs.interview_upcoming,
      title: 'my-interview',
      page,
      limit
    }
  })

  useEffect(() => {
    setPage(1)
  }, [filterValue.filterBy])

  const fetchNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const fetchPreviousPage = useCallback(() => {
    setPage(page - 1)
  }, [page])

  useEffect(() => {
    user?.timezone &&
      onChangeFilter({
        fromDatetime: addTzToDate(
          formatISO(startOfMonth(new Date())),
          user?.timezone
        ),
        toDatetime: addTzToDate(
          formatISO(lastDayOfMonth(new Date())),
          user?.timezone
        ),
        attendeeIds: [
          {
            value: String(user?.id),
            supportingObj: {
              name: user?.fullName || '',
              defaultColour: user?.defaultColour
            },
            avatar: user.avatarVariants?.thumb?.url,
            avatarVariants: user?.avatarVariants
          }
        ]
      })
  }, [
    user?.avatarVariants,
    user?.defaultColour,
    user?.fullName,
    user?.id,
    user?.timezone
  ])

  const updateInterview = useCallback<
    (data: InterviewParamsType) => Promise<void>
  >(
    (data) => {
      return clientGraphQL
        .mutation(QueryUpdateInterviewMutation, data)
        .toPromise()
        .then(
          (rs: {
            data: {
              interview: {
                id: string
                fromDatetime: string
                toDatetime: string
                ikitFeedbacksSummary: Array<{}>
              }
            }
          }) => {
            return rs.data
          }
        )
    },
    [clientGraphQL]
  )

  const refetchAndCheckLastItem = useCallback(
    (previousTypeAction?: 'delete' | 'edit') => {
      if (
        previousTypeAction === 'delete' &&
        page > 1 &&
        (data?.meta.totalRowCount || 0) % 4 === 1
      ) {
        //in case user delete the last item in page > 1, it will load previous page
        fetchPreviousPage()
      } else refetch()
    },
    [page, data]
  )

  return {
    interviewsListControl: {
      data,
      defaultPageSize: limit,
      isFetching,
      error,
      page,
      refetch: refetchAndCheckLastItem,
      fetchNextPage,
      fetchPreviousPage
    },
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      interviewUpdateAction: {
        updateInterview: (args: InterviewParamsType) => updateInterview(args)
      }
    }
  }
}

export default useMyInterviewsManagement
