import { gql } from 'urql'
import { MyReferralType } from '../types'

const QueryMyReferrals = gql<
  {
    myReferralsList: {
      collection: Array<MyReferralType>
      metadata: {
        totalCount: number
        extras: {
          not_hired_count: number
          in_process_count: number
          hired_count: number
          my_referral_link: string
        }
      }
    }
  },
  {
    limit?: number
    page?: number
  }
>`
  query ($limit: Int, $page: Int) {
    myReferralsList(limit: $limit, page: $page) {
      collection {
        id
        introduction
        profile {
          id
          fullName
          email
          avatarVariants
        }
        job {
          id
          title
          slug
          tenant {
            slug
          }
        }
        status
        applicant {
          id
          jobStage {
            stageLabel
          }
          currentStagedDate
        }
      }

      metadata {
        totalCount
        extras
      }
    }
  }
`

export default QueryMyReferrals
