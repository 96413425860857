import { useCallback, useEffect, useState } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import useBoundStore from '~/lib/store'
import QueryInterviewDetail from '../graphql/query-interviews-detail'
import QueryTenantMembers from '../graphql/query-tenant-members'
import { InterviewDetailType, InterviewParamsType } from '../types'

export type EditModalProps = {
  open: boolean
  interviewId?: number
  newDataInterview?: InterviewDetailType
  onCloseEditModal?: () => void
  onReopenEditModal?: () => void
  scheduleCallback?: () => void
}

const defaultEditModal = { open: false, interviewId: undefined }

const useScheduleInterviewCalendarHook = ({
  updateInterview
}: {
  updateInterview: (args: InterviewParamsType) => Promise<void>
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const setToast = useBoundStore((state) => state.setToast)
  const [editModal, setEditModal] = useState<EditModalProps>(defaultEditModal)
  const [interview, setInterview] = useState<InterviewDetailType | undefined>()

  const fetchInterview = useCallback(() => {
    return clientGraphQL
      .query(QueryInterviewDetail, {
        id: Number(editModal?.interviewId)
      })
      .toPromise()
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: { interviewsShow: InterviewDetailType }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              setToast
            })
          }

          const { interviewsShow } = result.data
          setInterview(interviewsShow)

          return
        }
      )
  }, [editModal])

  const onCloseEditModal = useCallback(() => {
    setEditModal(defaultEditModal)
  }, [])

  const onReopenEditModal = useCallback(() => {
    setEditModal({ ...editModal, open: true })
  }, [editModal])

  const onScheduleInterview = useCallback<
    (data: InterviewParamsType) => Promise<boolean>
  >(
    (data) => {
      return updateInterview(data).then(() => {
        editModal?.scheduleCallback && editModal.scheduleCallback()
        return false
      })
    },
    [updateInterview, editModal]
  )

  const fetchAssigneesOptions = (params = {}) =>
    clientGraphQL
      .query(QueryTenantMembers, { ...params })
      .toPromise()
      .then((result: IResponseContextResult<any>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { tenantMembers } = result.data
        const collection = tenantMembers?.collection || []
        const metadata = tenantMembers?.metadata || {}

        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            supportingObj: {
              name: item.fullName,
              description: item.email,
              defaultColour: item.defaultColour
            }
          }
        })

        return { metadata, collection: cloneData }
      })

  useEffect(() => {
    if (!!editModal.interviewId) {
      fetchInterview()
    } else setInterview(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModal.interviewId])

  return {
    editModal,
    interview: {
      data: interview,
      fetchData: fetchInterview
    },
    setEditModal,
    onCloseEditModal,
    onReopenEditModal,
    onScheduleInterview,
    fetchAssigneesOptions
  }
}

export default useScheduleInterviewCalendarHook
