import { gql } from 'urql'
import { AssigneeType } from '../types'

const QueryTenantMembers = gql<
  {
    tenantMembers: {
      collection: Array<AssigneeType>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    page?: number
    limit?: number
    search?: string
    jobId?: number
  }
>`
  query ($page: Int, $limit: Int, $search: String, $jobId: Int) {
    tenantMembers(page: $page, limit: $limit, search: $search, jobId: $jobId) {
      collection {
        id
        email
        fullName
        avatarVariants
        defaultColour
        roles {
          id
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantMembers
