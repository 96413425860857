import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { IParamsTableInfinity } from '~/core/@types/global'

export interface IPagePaginationResult<PageItem extends { id?: string }> {
  data: PageItem[]
  meta: { totalRowCount: number; extras?: any }
}

export const usePaginationQuerySearch = <PageItem extends { id?: string }>({
  fetchData,
  queryKey,
  enabled = true
}: {
  fetchData: (
    pageParam: IParamsTableInfinity
  ) => Promise<IPagePaginationResult<PageItem>>
  queryKey?: object
  enabled?: boolean
}) => {
  const [globalFilter, setGlobalFilter] = useState('')

  const { status, data, error, isFetching, refetch } = useQuery(
    ['table-data', queryKey],
    async (pageParams): Promise<IPagePaginationResult<PageItem>> => {
      const getQueryKey = pageParams?.queryKey?.[1] as IParamsTableInfinity
      const params = {
        ...getQueryKey,
        search: getQueryKey?.search ? getQueryKey.search : globalFilter,
        key: undefined
      }

      const fetchedData = fetchData(params)
      return fetchedData
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabled
    }
  )

  return {
    refetch,
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter
  }
}
