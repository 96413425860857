import { useTranslation } from 'next-i18next'
import React from 'react'
import HeadMetaTags from '~/components/HeadMetaTags'
import { PUBLIC_APP_NAME } from '~/core/constants/env'
import If from '~/core/ui/If'
import useBoundStore from '~/lib/store'
import DashboardContainer from '~/features/dashboard'
import withTranslateProvider from 'src/hoc/with-translate-provider'

const DashboardPage = () => {
  const { t } = useTranslation()
  const currentRole = useBoundStore((state) => state.currentRole)

  return (
    <>
      <HeadMetaTags
        title={`${t(`common:seo:dashboard`, { PUBLIC_APP_NAME })}`}
      />
      <If condition={currentRole?.id}>
        <DashboardContainer />
      </If>
    </>
  )
}

export default withTranslateProvider(DashboardPage)
