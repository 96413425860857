import { useCallback, useMemo, useState } from 'react'
import { AnyVariables, DocumentInput } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import {
  IPagePaginationResult,
  usePaginationQuerySearch
} from '~/lib/hooks/use-pagination-query-search'
import useBoundStore from '~/lib/store'
import { JOB_STATUS_ENUM } from '../../jobs/utilities/enum'

const limit = 8

export const useMyJobManagement = ({
  query
}: {
  query?: DocumentInput<any, AnyVariables>
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const [page, setPage] = useState<number>(1)
  const setToast = useBoundStore((state) => state.setToast)
  const fetchData = useCallback(
    (
      pageParam = {} as { page?: number; limit?: number }
    ): Promise<IPagePaginationResult<any>> => {
      return clientGraphQL
        .query(query, {
          page: pageParam.page || page,
          limit,
          status: [
            JOB_STATUS_ENUM.publish,
            JOB_STATUS_ENUM.internal,
            JOB_STATUS_ENUM.draft
          ]
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.reports.overview,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                currentPage: 1
              }
            }
          }

          const collection = Object.values(result.data)?.[0]?.collection || []
          const metadata = Object.values(result.data)?.[0]?.metadata || {}

          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              currentPage: metadata.currentPage
            }
          }
        })
    },
    [clientGraphQL, page, query, setToast]
  )
  const {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    refetch
  } = usePaginationQuerySearch<any>({
    fetchData,
    queryKey: {
      key: 'my-job-dashboard',
      page,
      limit
    }
  })

  const fetchNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const fetchPreviousPage = useCallback(() => {
    setPage(page - 1)
  }, [page])
  return {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    fetchNextPage,
    fetchPreviousPage
  }
}
