import { gql } from 'urql'
import { InterviewCandidateListResponseType } from '../../calendar/types'

const QueryInterviewsCandidateList = gql<
  InterviewCandidateListResponseType,
  {
    page?: number
    limit?: number
    fromDatetime?: string
    toDatetime?: string
    attendeeIds?: Array<number>
    profileId?: number
    applicantId?: number
    filterBy?: string
  }
>`
  query (
    $page: Int
    $limit: Int
    $fromDatetime: ISO8601DateTime
    $toDatetime: ISO8601DateTime
    $attendeeIds: [Int!]
    $profileId: Int
    $applicantId: Int
    $filterBy: String
  ) {
    interviewsList(
      page: $page
      limit: $limit
      fromDatetime: $fromDatetime
      toDatetime: $toDatetime
      attendeeIds: $attendeeIds
      profileId: $profileId
      applicantId: $applicantId
      filterBy: $filterBy
    ) {
      collection {
        id
        fromDatetime
        toDatetime
        eventType
        eventTypeDescription
        applicant {
          id
        }
        profile {
          id
          fullName
        }
        location {
          id
          address
          city
          state
          country
        }
        job {
          id
          title
          company {
            name
          }
        }
        ikitFeedbacksSummary
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryInterviewsCandidateList
