import { CellContext, HeaderContext } from '@tanstack/react-table'
import Link from 'next/link'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Badge } from '~/core/ui/Badge'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import IconWrapper from '~/core/ui/IconWrapper'
import { TablePagination } from '~/core/ui/TablePagination'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { formatAddressLocation } from '~/core/utilities/common'
import QueryCompanyJobList from '~/lib/features/dashboard/graphql/query-company-job-list'
import QueryJobList from '~/lib/features/dashboard/graphql/query-job-list'
import { useMyJobManagement } from '~/lib/features/dashboard/hooks/use-my-job-management'
import { JOB_DOT_STATUS } from '~/lib/features/jobs/utilities/enum'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { IJobCard } from '../Jobs/Listing/JobCard'

const Show = (props: { if: boolean; children: ReactNode }) =>
  props.if ? <>{props.children}</> : <></>

const MyJobView = () => {
  const { t } = useTranslation()
  const { isCompanyKind: isAgencyCompany, isLoaded } = useDetectCompanyWithKind(
    {
      kind: AGENCY_TENANT
    }
  )

  const { data, fetchNextPage, fetchPreviousPage, isFetching } =
    useMyJobManagement({
      query: isAgencyCompany ? QueryCompanyJobList : QueryJobList
    })

  return (
    <>
      <div className="flex h-[48px] w-full flex-row items-center px-4 pb-1 pt-2">
        <div className="flex flex-1 flex-row items-center space-x-1.5">
          <TypographyText className="text-xl font-medium text-gray-900">
            {t(`dashboard:myJob:title`)}
          </TypographyText>
          <Tooltip
            mode="icon"
            align="start"
            content={`${t('dashboard:myJob:tooltip')}`}>
            <IconWrapper size={14} name="InfoIcon" />
          </Tooltip>
        </div>
        <Link href={configuration.path.jobs.list}>
          <TextButton underline={false}>{t(`button:viewAll`)}</TextButton>
        </Link>
      </div>
      <TablePagination
        textOverride={{
          of: `${t('label:of')}`
        }}
        emptyConfig={{
          classNameEmpty: 'flex items-center h-auto justify-center h-full',
          title: `${t('dashboard:myJob:empty:title')}`,
          titleSearch: undefined
        }}
        tableConfig={{
          defaultPageSize: 8
        }}
        dataQuery={{
          isFetching,
          fetcher: {
            fetchNextPage,
            fetchPreviousPage
          },
          data
        }}
        columns={[
          {
            accessorKey: 'jobTitle',
            header: () => t(`dashboard:myJob:table:jobTitle`),
            cell: (info: CellContext<IJobCard, undefined>) => {
              const job = info?.row?.original
              return (
                <div>
                  <div className="flex w-full items-center space-x-1.5">
                    <Tooltip content={job?.statusDescription}>
                      <Dot
                        size="xl"
                        className="flex-none"
                        color={
                          JOB_DOT_STATUS(
                            job?.status || 'gray'
                          ) as IDotColorProps
                        }
                      />
                    </Tooltip>

                    <Tooltip content={`${job?.title}`}>
                      <Link
                        href={
                          job.status === 'draft'
                            ? configuration.path.jobs.edit(parseInt(job.id))
                            : configuration.path.jobs.detail(parseInt(job.id))
                        }>
                        <TypographyText className="line-clamp-1 max-w-[500px] break-all text-sm font-medium text-gray-900 hover:underline">
                          {job?.title}
                        </TypographyText>
                      </Link>
                    </Tooltip>
                    {(job?.statistic?.newApplicantCount || 0) > 0 && (
                      <div className="ml-2">
                        <Badge
                          color="green"
                          size="md"
                          radius="circular"
                          classNameText="truncate">
                          {job?.statistic?.newApplicantCount} {t('label:new')}
                        </Badge>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center space-x-1.5">
                    {isAgencyCompany === true ? (
                      <>
                        <Show if={!!job.company?.name}>
                          <IconWrapper
                            size={12}
                            name="Building"
                            className="flex-none text-gray-600"
                          />
                          <Tooltip content={job.company?.name}>
                            <Link
                              href={configuration.path.agency.companyDetail(
                                Number(job?.company?.id)
                              )}>
                              <TypographyText className="line-clamp-1 max-w-[200px] text-sm font-medium text-gray-800 hover:underline">
                                {job.company?.name}
                              </TypographyText>
                            </Link>
                          </Tooltip>
                        </Show>
                        <Show
                          if={job.jobLocations && job.jobLocations.length > 0}>
                          {job.jobLocations?.length ? (
                            <>
                              {job.jobLocations?.length > 1 ? (
                                <Tooltip
                                  align="start"
                                  classNameAsChild="line-clamp-1"
                                  content={
                                    <>
                                      {job.jobLocations.map(
                                        (item, index: number) => (
                                          <div key={index}>
                                            {formatAddressLocation({
                                              location: {
                                                state: item.state,
                                                country: item.country
                                              }
                                            })}
                                          </div>
                                        )
                                      )}
                                    </>
                                  }>
                                  <TypographyText className="line-clamp-1 text-sm text-gray-600">
                                    {t('job:jobCard:countLocation', {
                                      countLocation: job.jobLocations?.length
                                    })}
                                  </TypographyText>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  classNameAsChild="line-clamp-1"
                                  content={formatAddressLocation({
                                    location: {
                                      state: job.jobLocations?.[0]?.state,
                                      country: job.jobLocations?.[0]?.country
                                    }
                                  })}>
                                  <TypographyText className="truncate text-sm font-normal text-gray-600">
                                    {formatAddressLocation({
                                      location: {
                                        state: job.jobLocations?.[0]?.state,
                                        country: job.jobLocations?.[0]?.country
                                      }
                                    })}
                                  </TypographyText>
                                </Tooltip>
                              )}
                            </>
                          ) : null}
                        </Show>
                      </>
                    ) : (
                      <>
                        <Show
                          if={job.jobLocations && job.jobLocations.length > 0}>
                          <div className="ml-3.5 flex max-w-[50%] items-center space-x-2 break-all">
                            <IconWrapper
                              size={12}
                              name="MapPin"
                              className="flex-none text-gray-600"
                            />
                            {job.jobLocations?.length ? (
                              <>
                                {job.jobLocations?.length > 1 ? (
                                  <Tooltip
                                    align="start"
                                    content={
                                      <>
                                        {job.jobLocations.map(
                                          (item, index: number) => (
                                            <div key={index}>{`${
                                              item.name ? item.name : ''
                                            }`}</div>
                                          )
                                        )}
                                      </>
                                    }>
                                    <TypographyText className="line-clamp-1 text-sm text-gray-700">
                                      {t('job:jobCard:countLocation', {
                                        countLocation: job.jobLocations?.length
                                      })}
                                    </TypographyText>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    classNameAsChild="line-clamp-1"
                                    content={job.jobLocations[0].name}>
                                    <TypographyText className="line-clamp-1 text-sm text-gray-700">
                                      {job.jobLocations[0].name}
                                    </TypographyText>
                                  </Tooltip>
                                )}
                              </>
                            ) : null}
                          </div>
                        </Show>
                        <Show if={!!job.department?.name}>
                          <div className="ml-2 mr-2 h-[2px] w-[2px] flex-none rounded-full bg-gray-400" />
                          <div className="flex max-w-[50%] items-center break-all">
                            <IconWrapper
                              size={12}
                              name="Network"
                              className="mr-2 flex-none text-gray-600"
                            />
                            <Tooltip content={job.department?.name}>
                              <TypographyText className="line-clamp-1 text-sm text-gray-700">
                                {job.department?.name}
                              </TypographyText>
                            </Tooltip>
                          </div>
                        </Show>
                      </>
                    )}
                  </div>
                </div>
              )
            },
            footer: (props: HeaderContext<IJobCard, undefined>) =>
              props.column.id,
            size: 500,
            maxSize: 600
          },
          {
            accessorKey: 'member',
            header: () => t(`dashboard:myJob:table:member`),
            cell: (info: CellContext<IJobCard, undefined>) => {
              const job = info?.row?.original
              const listUsers = isAgencyCompany
                ? [
                    ...(job?.jobRecruiters || []),
                    ...(job?.clientMembers || []).map((client) => ({
                      id: client.id,
                      user: {
                        fullName: client?.fullName,
                        email: client?.email,
                        avatarVariants: client?.avatarVariants,
                        defaultColour: client?.defaultColour
                      }
                    }))
                  ]
                : job?.jobRecruiters || []
              return (
                <div className="flex items-center space-x-1.5">
                  <AvatarGroup
                    size="xs"
                    tooltipAlignAvatarCount="end"
                    source={listUsers.map((item) => ({
                      id: parseInt(item.id),
                      alt: item.user?.fullName || item.user?.email,
                      tooltip: item.user?.fullName || item.user?.email,
                      src: item.user?.avatarVariants?.thumb?.url,
                      defaultColour: item.user?.defaultColour
                    }))}
                  />
                </div>
              )
            },
            footer: (props: HeaderContext<IJobCard, undefined>) =>
              props.column.id,
            size: 135
          },
          {
            accessorKey: 'totalApplicants',
            header: () => (
              <TypographyText className="text-center">
                {t(`dashboard:myJob:table:totalApplications`)}
              </TypographyText>
            ),
            cell: (info: CellContext<IJobCard, undefined>) => {
              const job = info?.row?.original
              return (
                <div className="text-center">{job?.applicants?.length}</div>
              )
            },
            footer: (props: HeaderContext<IJobCard, undefined>) =>
              props.column.id,
            size: 135
          },
          {
            accessorKey: 'in-process',
            header: () => (
              <TypographyText className="text-center">
                {t(`dashboard:myJob:table:inProgress`)}
              </TypographyText>
            ),
            cell: (info: CellContext<IJobCard, undefined>) => {
              const job = info?.row?.original
              return (
                <div className="text-center">
                  {job?.statistic?.inprocessGroupCount}
                </div>
              )
            },
            footer: (props: HeaderContext<IJobCard, undefined>) =>
              props.column.id,
            size: 135
          },
          {
            accessorKey: 'hired',
            header: () => (
              <TypographyText className="text-center">
                {t(`dashboard:myJob:table:hired`)}
              </TypographyText>
            ),
            cell: (info: CellContext<IJobCard, undefined>) => {
              const job = info?.row?.original
              return (
                <div className="text-center">
                  {job?.statistic?.hiredGroupCount}
                </div>
              )
            },
            footer: (props: HeaderContext<IJobCard, undefined>) =>
              props.column.id,
            size: 135
          }
        ]}
        classNameTable={'h-[calc(100%_-_100px)] mt-3 px-4'}
        classNamePaginationWrapper={
          (data?.data || [])?.length > 0 ? 'pb-0 mb-0 pr-1' : ''
        }
        isHeaderSticky
      />
    </>
  )
}

export default MyJobView
