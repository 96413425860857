/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import configuration from '~/configuration'
import { TablePagination } from '~/core/ui/TablePagination'
import { TypographyText } from '~/core/ui/Text'
import { CellContext, HeaderContext } from '@tanstack/react-table'
import { Tooltip } from '~/core/ui/Tooltip'

import Link from 'next/link'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { useTranslation } from 'react-i18next'

import { truncateTextWithDot } from '~/core/utilities/common'
import useOpenJobsDashboard from '~/lib/features/dashboard/hooks/use-open-jobs-dashboard'
import { defaultFormatDate } from '~/core/utilities/format-date'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import MatchedRankDetailWrapper from '../Candidates/Profile/components/Recommendation/MatchedRankDetailWrapper'
import {
  EmployeeProfileType,
  JobDetailType,
  ReferralFormType
} from '~/lib/features/referrals/types'
import MatchedRankDetail, {
  ModalMatchedRankType
} from '../Candidates/Profile/components/Recommendation/MatchedRankDetail'

import ActionOpenJobInternal from './Components/ActionOpenJobInternal'
import ReferralModal from '../CareerHub/[id]/ReferralModal'
import { ReferralSettingType } from '~/lib/features/settings/referrals/types'
import { TextButton } from '~/core/ui/TextButton'
import pathConfiguration from 'src/configuration/path'
import useBoundStore from '~/lib/store'

const JobInfoWrapper: FC<{
  iconMenu?: LucideIconName
  text: string
  tooltip?: React.ReactElement
  className?: string
}> = ({ iconMenu, text, tooltip, className }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <div className="mr-1.5">
        <IconWrapper name={iconMenu} size={12} className="text-gray-600" />
      </div>
      {tooltip ? (
        <Tooltip content={tooltip}>
          <TypographyText className="line-clamp-1 max-w-[250px] break-all text-sm text-gray-700">
            {text}
          </TypographyText>
        </Tooltip>
      ) : (
        <TypographyText className="text-sm text-gray-700">
          {text}
        </TypographyText>
      )}
    </div>
  )
}

const MyJobInternalView = ({
  setCountOpenJobs,
  dataReferral,
  employeeProfile,
  fetchEmployeeProfile
}: {
  fetchEmployeeProfile: () => void
  dataReferral: ReferralSettingType | undefined
  employeeProfile: EmployeeProfileType | undefined
  setCountOpenJobs: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const setModalMatchedRankRef =
    useRef<React.Dispatch<React.SetStateAction<ModalMatchedRankType>>>()
  const referralPortal = dataReferral?.values?.referral_portal
  const [openReferralModal, setOpenReferralModal] = useState<boolean>(false)
  const [referralJob, setReferralJob] = useState<ReferralFormType>()
  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    refetchFirstPage,
    onRefetch
  } = useOpenJobsDashboard()
  const onCloseReferralModal = useCallback(() => {
    setReferralJob({})
    setOpenReferralModal(false)
  }, [setOpenReferralModal])

  const callbackOnFinishReferralModal = async () => {
    refetchFirstPage()
  }

  const renderLocations = useCallback(
    (locations: JobDetailType['jobLocations']) => {
      const locationFormatted = (locations || [])?.map(
        (location) =>
          `${location.state || ''}${
            !!location.state && !!location.country ? ', ' : ''
          }${location.country || ''}`
      )
      const locationFormattedUnique = locationFormatted?.filter(
        (location, index) => locationFormatted.indexOf(location) === index
      )
      return (
        <JobInfoWrapper
          iconMenu="MapPin"
          className="flex max-w-[50%]"
          tooltip={
            <>
              {locationFormattedUnique?.map((location) => (
                <div key={`open-job-location-${location}`}>{location}</div>
              ))}
            </>
          }
          text={
            locationFormattedUnique.length > 1
              ? `${locationFormattedUnique.length} ${t('label:locations')}`
              : truncateTextWithDot(locationFormattedUnique?.[0])
          }
        />
      )
    },
    []
  )

  useEffect(() => {
    if (data?.meta?.totalRowCount) {
      setCountOpenJobs(data?.meta?.totalRowCount)
    }
  }, [data, setCountOpenJobs])

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'jobTitle',
          header: () => (
            <TypographyText className="text-left text-xs font-normal text-gray-700 dark:text-gray-300">
              {t(`dashboard:myJob:table:jobTitle`)}
            </TypographyText>
          ),
          cell: (info: CellContext<JobDetailType, undefined>) => {
            const job = info?.row?.original
            const rank = job?.recommendationMatchedFields?.filter(
              (r) => r.field === 'total'
            )[0]
            return (
              <div>
                <div className="flex w-full items-center space-x-1.5">
                  {job.recommendationMatchedFields && rank.total_rate > 0 && (
                    <div className="mr-1.5">
                      <MatchedRankDetailWrapper
                        openMatchedRank={setModalMatchedRankRef?.current}
                        job={job}
                      />
                    </div>
                  )}
                  <Tooltip content={`${job?.title}`}>
                    <Link
                      href={pathConfiguration?.careerHub.jobDetail({
                        tenantSlug: String(user?.currentTenant?.slug),
                        jobId: job?.id
                      })}
                      target="_blank">
                      <TypographyText className="line-clamp-1 break-all text-sm font-medium text-gray-900 hover:underline">
                        {job?.title}
                      </TypographyText>
                    </Link>
                  </Tooltip>
                </div>
                <div className="flex w-full items-center space-x-1.5">
                  {(job?.jobLocations || [])?.length > 0 &&
                    !!job?.jobLocations?.[0]?.country &&
                    renderLocations(job?.jobLocations)}
                  {(job?.jobLocations || [])?.length > 0 &&
                    !!job?.jobLocations?.[0].country &&
                    !!job?.department?.name && (
                      <div className="h-0.5 w-0.5 rounded bg-gray-400" />
                    )}
                  {!!job?.department?.name && (
                    <div className="flex max-w-[50%] items-center break-all">
                      <IconWrapper
                        size={12}
                        name="Network"
                        className="mr-2 flex-none text-gray-600"
                      />
                      <Tooltip content={job.department?.name}>
                        <TypographyText className="line-clamp-1 text-sm text-gray-700">
                          {job.department?.name}
                        </TypographyText>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            )
          },
          footer: (props: HeaderContext<JobDetailType, undefined>) =>
            props.column.id,
          size: 600,
          maxSize: 780
        },
        {
          accessorKey: 'referrals',
          header: () => (
            <TypographyText className="text-left text-xs font-normal text-gray-700 dark:text-gray-300">
              {t('dashboard:internal:job:referrals')}
            </TypographyText>
          ),
          cell: (info: CellContext<JobDetailType, undefined>) => {
            const job = info?.row?.original
            return (
              <div className="flex">
                {job?.referralsCount > 0 && !referralPortal?.job_only && (
                  <TypographyText className="whitespace-nowrap text-sm text-gray-600">
                    {job?.referralsCount}
                  </TypographyText>
                )}
              </div>
            )
          },
          footer: (props: HeaderContext<JobDetailType, undefined>) =>
            props.column.id,
          size: 124,
          maxSize: 161
        },
        {
          accessorKey: 'createdAt',
          header: () => (
            <TypographyText className="text-center text-xs font-normal text-gray-700 dark:text-gray-300">
              {t('dashboard:internal:job:publishDate')}
            </TypographyText>
          ),
          cell: (info: CellContext<JobDetailType, undefined>) => {
            const job = info?.row?.original
            return (
              <div className="text-center">
                {job?.createdAt && (
                  <TypographyText className="whitespace-nowrap text-sm text-gray-600">
                    {defaultFormatDate(new Date(job.createdAt))}
                  </TypographyText>
                )}
              </div>
            )
          },
          footer: (props: HeaderContext<JobDetailType, undefined>) =>
            props.column.id,
          size: 162,
          maxSize: 210
        },
        {
          accessorKey: 'action',
          header: () => (
            <TypographyText className="text-center text-xs font-normal text-gray-600 dark:text-gray-300"></TypographyText>
          ),
          cell: (info: CellContext<JobDetailType, undefined>) => {
            const job = info?.row?.original

            return (
              <ActionOpenJobInternal
                fetchEmployeeProfile={fetchEmployeeProfile}
                employeeProfile={employeeProfile}
                refetch={onRefetch}
                dataReferral={dataReferral}
                setReferralJob={setReferralJob}
                setOpenReferralModal={setOpenReferralModal}
                job={job}
              />
            )
          },
          footer: (props: HeaderContext<JobDetailType, undefined>) =>
            props.column.id,
          size: 252
        }
      ].filter((col) => !!col),
    [dataReferral, data, employeeProfile]
  )

  return (
    <MatchedRankDetail>
      {(setModalMatchedRank) => {
        if (setModalMatchedRank) {
          setModalMatchedRankRef.current = setModalMatchedRank
        }

        return (
          <>
            <TablePagination
              textOverride={{
                of: `${t('label:of')}`
              }}
              emptyConfig={{
                classNameEmpty:
                  'flex items-center h-auto justify-center h-full',
                title: `${t('dashboard:internal:job:empty:title')}`,
                titleSearch: undefined
              }}
              tableConfig={{
                defaultPageSize: 8
              }}
              dataQuery={{
                isFetching,
                fetcher: {
                  fetchNextPage,
                  fetchPreviousPage
                },
                data
              }}
              columns={columns}
              columnVisibility={{ referrals: !referralPortal?.job_only }}
              classNameTable={'h-[calc(100%_-_90px)] mt-2 px-4'}
              classNamePaginationWrapper={
                (data?.data || [])?.length > 0 ? 'pb-0 mb-0 pr-1' : ''
              }
              isHeaderSticky
            />
            <ReferralModal
              openReferralModal={openReferralModal}
              onClose={onCloseReferralModal}
              defaultValue={referralJob}
              callbackOnFinish={callbackOnFinishReferralModal}
            />
          </>
        )
      }}
    </MatchedRankDetail>
  )
}

export default MyJobInternalView
